import React from "react"
import { graphql, Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import NavBar from "../components/NavBar"
import Footer from "../components/Footer"
import Metatags from "../components/metatags"
import UseSiteMetadata from "../components/UseSiteMetadata"

const Outsourcing = ({ pageContext, data, location }) => {
  const { siteUrl } = UseSiteMetadata()
  const  fullUrl = `${siteUrl}${location.pathname}`
  const schemaData = {
    "@context": "https://schema.org",
    "@graph": [
      {
        "@context": "https://schema.org",
        "@type": "Organization",
        email: "info@netbistrot.com",
        name: "NetBistrot",
        logo:"https://netbistrot.com/netbistrot-icon-540.png",
      },
      {
        "@type": "WebSite",
        "@id": `https://netbistrot.com${location.pathname}`,
        url: `https://netbistrotcom${location.pathname}`,
        name: "NetBistrot",
        inLanguage: "es-ES",

      },
    ],
  }
  return (
    <>
      <Metatags
        title="NetBistrot - Digital agency | outsourcing"
        description="outsourcing, gatsby, gatsbyjs, aws, aws services, terraform"
        url={fullUrl}
        image="https://netbistrot.com/netbistrot-icon-540.png"
        keywords="outsourcing, gatsby, gatsbyjs, aws, aws services, terraform"
        language={pageContext.langKey}
        schemaData={schemaData}
      />
      <NavBar />
      <div className="h-10" />
      <div className="container mx-auto">
        <div className="mx-2 md:mx-0">
          <div className="grid grid-cols-1 md:grid-cols-12 gap-4">
            <div className="col-span-2" />
            <div className="col-span-10">
              <h1>Outsourcing</h1>
            </div>
          </div>
          <div className="h-8" />
          <div className="grid grid-cols-1 md:grid-cols-12 gap-4">
            <div className="col-span-4" />
            <div className="col-span-6">
              <span className="text-lg">
                Estamos disponibles para externalizar proyectos a otras
                empresas.
                <br />
                <br />
                Nos comprometemos a precios y plazos previamente acordados.
                <br />
                <br />
                Consulte nuestras áreas de especialización:
                <br />
                <br />
              </span>
              <div className="h-8" />
            </div>
          </div>
          <div className="h-8" />
          <div className="grid grid-cols-1 md:grid-cols-12 gap-4">
            <div className="col-span-2" />
            <div className="col-span-2 text-center">
              <GatsbyImage
                image={getImage(data.gatsby)}
                backgroundColor={false}
                alt="gatsby"
                className="aspect-square max-w-max"
              />
            </div>
            <div className="col-span-6">
              <span className="text-lg">
                Gatsby websites
                <br />
                <br />
                Tenemos algunos años de experiencia en el desarrollo de sitios
                web con Gatsby y es nuestra principal opción de front end.
                <br />
                <br />
                Además de las características de Gatsby, hemos desarrollado
                varias soluciones para obtener la mejor puntuación posible de
                Lighthouse.
                <br />
                <br />
              </span>
              <div className="h-8" />
            </div>
          </div>
          <div className="h-8" />
          <div className="grid grid-cols-1 md:grid-cols-12 gap-4">
            <div className="col-span-2" />
            <div className="col-span-2 text-center">
              <GatsbyImage
                image={getImage(data.aws)}
                backgroundColor={false}
                alt="aws"
                className="aspect-square max-w-max"
              />
            </div>
            <div className="col-span-6">
              <span className="text-lg">
                AWS
                <br />
                <br />
                Tenemos mucha experiencia en el uso de los servicios de AWS para
                implementar varios sistemas serverless.
                <br />
                <br />
                Estos son los servicios con los que tenemos más experiencia:
                <br />
                <br />
                API Gateway
                <br />
                Cloudfront
                <br />
                DynamoDB
                <br />
                Lambda
                <br />
                Route 53
                <br />
                S3
                <br />
                Step Functions
                <br />
                <br />
              </span>
              <div className="h-8" />
            </div>
          </div>
          <div className="h-8" />
          <div className="grid grid-cols-1 md:grid-cols-12 gap-4">
            <div className="col-span-2" />
            <div className="col-span-2 text-center">
              <GatsbyImage
                image={getImage(data.terraform)}
                backgroundColor={false}
                alt="terraform"
                className="aspect-square max-w-max"
              />
            </div>
            <div className="col-span-6">
              <span className="text-lg">
                Terraform
                <br />
                <br />
                Recientemente, para automatizar las implementaciones de aws y
                centrarnos en los ciclos de ci/cd, hemos estado utilizando el
                framework terraform con excelentes resultados.
                <br />
                <br />
              </span>
              <div className="h-8" />
              <div className="text-center">
                <span className="text-2xl">
                  Ready to work together?
                  <br />
                  <br />
                </span>
                <Link
                  className="btn-icon rounded text-lg text-white bg-blue-600 hover:bg-blue-800 focus:outline-none focus:ring px-4 py-2 font-semibold"
                  to="/en/lets-work-together/"
                >
                  hagamos un proyecto de outsourcing
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="h-10" />
      <Footer lang="en" />
    </>
  )
}

export default Outsourcing

export const query = graphql`
  {
    aws: file(relativePath: { eq: "outsourcing/aws.png" }) {
      childImageSharp {
        gatsbyImageData(quality: 80, layout: CONSTRAINED, width: 80)
        id
      }
    }
    gatsby: file(relativePath: { eq: "outsourcing/gatsby.png" }) {
      childImageSharp {
        gatsbyImageData(quality: 80, layout: CONSTRAINED, width: 80)
        id
      }
    }
    terraform: file(relativePath: { eq: "outsourcing/terraform.png" }) {
      childImageSharp {
        gatsbyImageData(quality: 80, layout: CONSTRAINED, width: 80)
        id
      }
    }
  }
`
